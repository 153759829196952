import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import LoadingSpinner from "../LoadingSpinner";

interface WhereOperator {
  eq: string | number | boolean | null
  ne: string | number | boolean | null
  in: string[] | number[] | boolean[] | null
  like: string | null
  gt: string | number | null
  gte: string | number | null
  lt: string | number | null
  lte: string | number | null
}

type WhereArgument = Record<string, Partial<WhereOperator> | Array<Record<string, Partial<WhereOperator>>>> | null | undefined

type PaginationProps = {
  pageLoading: boolean;
  paginationState: {
    pageNumber: number;
    pageSize: number;
    where: WhereArgument
  };
  handleNextPage: (pageSize: number, pageNumber: number, where: WhereArgument) => void;
  handlePrevPage: (pageSize: number, pageNumber: number, where: WhereArgument) => void;
  handlePageSize?: (pageSize: number, pageNumber: number, where: WhereArgument) => void;
  hasNextPage: boolean | undefined;
  totalCount?: number | undefined;
  disablePageSize?: boolean;
  enableInfinitePagination?: boolean;
  pageSizeOptions?: number[];
}

export default function Pagination({
  pageLoading,
  paginationState,
  handleNextPage,
  handlePrevPage,
  handlePageSize,
  hasNextPage,
  totalCount,
  disablePageSize = false,
  enableInfinitePagination = false,
  pageSizeOptions = [10, 25, 50, 100]
}: PaginationProps) {
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-3 pb-5">
      <div className="d-flex align-items-center">
        <Button
          variant="secondary"
          className="me-1"
          onClick={() => handlePrevPage(
            paginationState.pageSize,
            paginationState.pageNumber - 1,
            paginationState.where ?? undefined)}
          disabled={(paginationState.pageNumber <= 1) || pageLoading}
        >
          {"<"}
        </Button>
        <Button
          variant="secondary"
          className="me-1"
          onClick={() => handleNextPage(
            paginationState.pageSize,
            paginationState.pageNumber + 1,
            paginationState.where ?? undefined)}
          disabled={totalCount != null 
            ? ((paginationState.pageNumber === (Math.ceil((totalCount ?? 0) / paginationState.pageSize))) || pageLoading)
            : hasNextPage === false || pageLoading
          }
        >
          {">"}
        </Button>
        <p className="mb-0 mx-2 text-dark">
          Page{" "}
          <strong>
            { !enableInfinitePagination
              ? <>
                {paginationState.pageNumber} of{" "}
                {(totalCount ?? 0) > 0
                  ? (Math.ceil((totalCount ?? 0) / paginationState.pageSize)) 
                  : 1
                }
              </>
              : <>
                {paginationState.pageNumber}
              </>
            }
          </strong>{" "}
        </p>
        { pageLoading && <LoadingSpinner/> }
      </div>
      { disablePageSize === false && handlePageSize != null
        && <div className="d-flex justify-content-md-end">
          <DropdownButton
            className="w-auto"
            aria-label="Show Chargers"
            id="show-chargers"
            title={`Show ${paginationState.pageSize}`}
            variant="secondary"
            onSelect={e => handlePageSize(
              parseInt(e ?? "0"),
              paginationState.pageNumber,
              paginationState.where ?? undefined) }
          >
            {pageSizeOptions.map(pageSize => (
              <Dropdown.Item key={pageSize} eventKey={pageSize}>
                Show {pageSize}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>  
      }
    </div>
  );
}